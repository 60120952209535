import LogoTEC from '../src/assets/images/clients/tec.svg';
import LogoSNCB from '../src/assets/images/clients/nmbs-sncb.svg';
import LogoDeLijn from '../src/assets/images/clients/delijn.svg';
import LogoSTIBMIVB from '../src/assets/images/clients/stib-mivb.svg';
import LogoRTBF from '../src/assets/images/clients/rtbf.svg';
import LogoStratec from '../src/assets/images/clients/stratec.svg';

export const logos = {
  title: 'Proudly trusted by',
  logoWall: [
    {
      logo: LogoRTBF,
      link: 'https://www.rtbf.be/',
      name: 'RTBF',
    },
    {
      logo: LogoSNCB,
      link: 'https://www.belgiantrain.be/fr/',
      name: 'SNCB-NMBS',
    },
    {
      logo: LogoSTIBMIVB,
      link: 'http://www.stib-mivb.be',
      name: 'STIB',
    },
    {
      logo: LogoDeLijn,
      link: 'https://www.delijn.be/fr/',
      name: 'De Lijn',
    },
    {
      logo: LogoTEC,
      link: 'https://www.letec.be/',
      name: 'Le Tec',
    },
    {
      logo: LogoStratec,
      link: 'http://www.stratec.be/',
      name: 'Stratec',
    },
  ],
};

export default logos;
