import styled from 'styled-components';
import { colors } from '../../theme/index';

export const Container = styled.div`
  width: 100%;
  display: block;
  padding: 64px;
  background: ${colors.lightGrey};
  @media (max-width: 768px){
    padding: 32px;
  }

  p {
    text-transform: uppercase;
    text-align: center;
    color: ${colors.darkGrey};
    margin-bottom: 24px;
  }
`;

export const List = styled.ul`
   padding: 0;
   display: flex;
   justify-content: center;
   @media (max-width: 768px){
     flex-direction: column;
      li{
        align-self: center;
        margin: 12px 0;
      }
   }
`;

export const ListItem = styled.li`
  list-style: none;
  width: auto;
  height: 40px;
  opacity: 0.5;
  margin: 0 24px;
  transition: all .3s cubic-bezier(0.2, 0.8, 0.4, 1);

  &:hover {
    opacity: 1;
  }

  img {
    height: 100%;
    width: auto;
  }
`;
