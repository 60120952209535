import PropTypes from 'prop-types';
import React from 'react';
import BodyText from 'stoomlink-commons-ui/BodyText';
import { logos } from '../../../content/logos';
import {
  Container,
  List,
  ListItem,
} from './styledComponent';

const LogoWall = ({ title, logoWall }) => (
  <Container>
    <BodyText>{title}</BodyText>
    <List>
      {logoWall.map((item, i) => {
        return (
          <ListItem key={`${item.name + i}`}>
            <a
              href={item.link}
              rel="noreferrer"
              target="_blank"
            >
              <img src={item.logo} alt={`${item.name} logo`} />
            </a>
          </ListItem>
        );
      })}
    </List>
  </Container>
);

LogoWall.propTypes = {
  title: PropTypes.string,
  logoWall: PropTypes.array,
};

LogoWall.defaultProps = logos;

export default LogoWall;
